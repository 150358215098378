import React from 'react'
import Sidebar from '@/components/base/Sidebar'

const Main = ({ children }) => {
    return (
        <div className="flex">
            <div>
                {/* sidebar */}
                <Sidebar />
            </div>
            {/* body */}
            <div className="ml-auto flex pl-4 w-[calc(100%-104px)]">
                <div className="min-h-[calc(100vh-0px)] w-full border-l border-interface-300 bg-common-white relative">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Main
