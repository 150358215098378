import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

const Logo = ({
    width = 273.52,
    height = 52,
    link = '/',
    className,
    src = '/logo.svg',
}) => {
    return (
        <div className={`h-[52px] w-[273.52px] ipad:w-[130px] ${className}`}>
            <Link href={link}>
                <Image
                    src={src}
                    alt={process.env.NEXT_PUBLIC_APP_NAME}
                    width={width}
                    height={height}
                    className="relative"
                />
            </Link>
        </div>
    )
}

export default Logo
