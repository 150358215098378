import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    users: null,
    keyword: null,
    product: null,
    autoSaveLoader: null,
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload
        },
        setUsers: (state, { payload }) => {
            state.users = payload
        },
        setKeyword: (state, { payload }) => {
            state.keyword = payload
        },
        setProduct: (state, { payload }) => {
            state.product = payload
        },
        setAutoSaveLoader: (state, { payload }) => {
            state.autoSaveLoader = payload
        },
    },
})

export const { setItem, setUsers, setKeyword, setProduct, setAutoSaveLoader } =
    sharedSlice.actions

export default sharedSlice.reducer
