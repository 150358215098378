import Link from 'next/link'
import map from 'lodash/map'
import styles from '@/styles/Sidebar.module.css'

const LeftNavigation = (props) => {
    return (
        <>
            <div className={`${styles.sidebarMenuList}`}>
                <ul className={styles.menuItem}>
                    {map(props.menuItems, (item, index) => (
                        <li
                            key={index}
                            className={props.activeNavigationClass(
                                item.path,
                                true
                            )}
                        >
                            {/* item.path === router.asPath ? styles.active : '' */}
                            <Link
                                href={{
                                    pathname: item.path,
                                    query: item.query,
                                }}
                            >
                                <span onClick={() => props.handleClick(index)}>
                                    <i className={item.icon} />
                                    {item.title}
                                </span>
                            </Link>
                            {item.subMenuItems &&
                                item.subMenuItems.length > 0 && (
                                    <i
                                        className={`icon-down-arrow ${styles.dropdown}`}
                                    />
                                )}
                            {props.activeMenu === index && (
                                <ul className={styles.subMenu}>
                                    {map(
                                        item.subMenuItems,
                                        (subMenuItem, subMenuIndex) => (
                                            <li
                                                className={props.activeNavigationClass(
                                                    subMenuItem.path,
                                                    false,
                                                    subMenuIndex
                                                )}
                                                key={subMenuIndex}
                                            >
                                                <Link href={subMenuItem.path}>
                                                    <span>
                                                        {subMenuItem.label}
                                                    </span>
                                                </Link>
                                            </li>
                                        )
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default LeftNavigation
