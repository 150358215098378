import styles from '@/styles/Button.module.css'
const Loader = (props) => {
    return (
        <div
            className={` ${props.className} ${
                props.dataLoader
                    ? 'data-loader flex items-center justify-center py-10'
                    : ''
            }`}
        >
            <span className={styles.spinner}>
                <div
                    className={`${styles.spinnerInner} ${props.spinnerClass}`}
                ></div>
            </span>
            {props.text && (
                <span className={styles.loaderMsg}>{props.text}</span>
            )}
        </div>
    )
}

export default Loader
