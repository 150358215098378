import Logo from '@/components/base/Logo'
import styles from '@/styles/Auth.module.css'
const AuthLayout = ({ children }) => {
    return (
        <div className="fixed w-full h-screen overflow-hidden">
            <div className="flex flex-row-reverse bg-interface-50">
                <div className={styles.authFormSection}>
                    <div className={styles.authLogo}>
                        <Logo link="/" />
                    </div>
                    <div className="mb-6">{children}</div>
                    <div className="absolute left-0 right-0 px-24 text-sm text-center bottom-4 text-interface-600">
                        &copy; 2017-{new Date().getFullYear()}{' '}
                        {process.env.NEXT_PUBLIC_APP_NAME}.
                        <span className="block mb-3">
                            Trademarks and brands are the property of their
                            respective owners
                        </span>
                        <p>Version Update: {process.env.BUILD_DATE}</p>
                    </div>
                </div>
                <div className={`h-screen w-full ${styles.bgImage}`}></div>
            </div>
        </div>
    )
}

export default AuthLayout
