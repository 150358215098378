import { useRouter } from 'next/router'
import Preloader from '@/components/base/Preloader'
import { useSession } from '@/contexts/Session'

const Auth = ({ children }) => {
    const { push, asPath, pathname } = useRouter()
    const { isAuthenticated, setNextURL, scopes, user } = useSession({
        required: true,
        onUnauthenticated() {
            setNextURL(asPath)
            if (asPath !== '/') {
                push('/')
            }
        },

        onAuthenticated() {
            const hasScope =
                scopes.includes(asPath.slice(1).replaceAll('/', '-')) ||
                scopes.includes(pathname.slice(1).replaceAll('/', '-'))

            if (scopes.length && !hasScope && pathname !== '/projects') {
                push(
                    `/projects?type=${
                        user?.projectTypeAccess === 'all'
                            ? 'housetypes'
                            : user?.projectTypeAccess
                    }&standard=${
                        user?.standardAccess === 'both'
                            ? ''
                            : user?.standardAccess
                    }`
                )
            }
        },
    })

    if (isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Auth
